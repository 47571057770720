// == PWD WP Sass Framework ==
// Print styles

// Inspired by:
// https://www.smashingmagazine.com/2015/01/designing-for-print-with-css/
// https://medium.com/alistapart/print-styles-b9a0783cd646

@import "common/variables";
@import "common/tools";

@page {
	margin: .5cm;
	orphans: 4;
	widows: 3;
}

body {
	text-align: left;
}

.header-theme,
.footer-theme,
nav,
.hidden-print {
	display: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
time,
button,
input[type="submit"],
input[type="reset"] {
	color: $base-text-color !important;
}

button,
input[type="submit"],
input[type="reset"] {
	padding: $base-input-text-padding;
	background-color: transparent;
	border: $base-input-text-border;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	page-break-after: avoid;
}

table,
figure {
	page-break-inside: avoid;
}

.col-print-12 {
	flex: 0 0 100%;
	max-width: $base-content-width + $grid-gutter-width;
	margin-left: 0;
}

.hero__image {
	display: none;
}
